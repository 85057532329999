import axios from "../utils/axios";

export const getCheckout = async (amount, currency, name, email) => {
  const {
    data: { response, error },
  } = await axios.post(`/.netlify/functions/fetch-stripe-checkout`, {amount, currency, name, email}, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return { error, response };
};
