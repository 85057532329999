import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';
import logo from '../images/earthshare_logo.png';
// const data = require('../data/data.json');

class Footer extends React.Component {
   render() {
      return (
       <div className="footer-container">
        <div className="footer-container-icon">
          Powered By<img src={logo} className="footer-container-icon-image"></img>
        </div>
       </div>
      );
   }
}

export default Footer;