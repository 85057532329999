import React, { Component } from 'react';
import { Elements, StripeProvider, CardElement } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import Donate from './components/donate.js';
import DonationOptions from './components/donation-options.js';
import LearnMoreButton from './components/learn-more.js';
import SocialComponent from './components/social-component.js';
import Checkout from './pages/page-donate.js';
import { Link, withRouter } from 'react-router-dom';
import heroImage from './images/hero_image.png';
import './App.scss';
const data = require('./data/data.json');

class App extends Component {
	renderBody = () => {
		return (
			<div>
				{data.body.map((paragraph) => {
					return <p>{paragraph}</p>;
				})}
			</div>
		);
	};

	render() {
		return (
			<div>
				<Header />
				<div className='topTitle'>
						<h1>{data.title}</h1>
					</div>
				<div className='row'>
					<div className='double-column'>
						<div className='column'>
							<div className='section-main'>
								<div className='section-main-title'>
									<img src={heroImage}></img>
									<div style={{ minWidth: '300px' }}></div>
								</div>

								<div className='section-main-description'>
									<p>
									This month as we focus on actionable changes we can personally make to lessen our environmental footprint, we also need to look at other causes working to do the same! Many environmental organizations in our area are working tirelessly to mitigate the impacts of climate change. We can continue to tweak small things in our daily routines to make an impact, but sometimes our impact is greater as a collective. Supporting local environmental non-profits expands your environmental impact and supports the local community. Join Experience Momentum this month as we donate to Viva Farms, Black Farmers Collective, Seattle Tilth, and Puget Soundkeeper. Experience Momentum will be matching any donation made to this campaign up to $2,500 so double your impact and donate today!									
									</p>
								</div>
							</div>
						</div>
					</div>

					<div className='column'>
						<div className='section-donate'>
							<Donate />
							<DonationOptions />
							<SocialComponent />
							<div class='section-donate-disclaimer'>
								*While we work to ensure accuracy, the stats indicated on this
								page may not be an exact reflection of actual activity.
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withRouter(App);
