import React from 'react';
import { Link, withRouter } from "react-router-dom";
import history from '../history';
import './donation-options.scss';
import CurrencyInput from 'react-currency-input';
import Modal from 'react-modal';
import Terms from '../pages/page-terms';
import axios from 'axios';
import qs from 'querystring';
const data = require('../data/data.json');

const config = {
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

class DonationOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      terms: false,
      showModal: false
    };

    this.cancel = '';

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleTermsChange = this.handleTermsChange.bind(this);
    // this.handleCauseChange = this.handleCauseChange.bind(this);
    this.handleRouting = this.handleRouting.bind(this);
  }


  handleAmountChange(event) {
    var amount = event.target.value.replace(/[^0-9 ]/g, "");
    this.setState({ amount: amount });
  }

  handleAmountChange(event, maskedvalue, floatvalue) {
    this.setState({amount: floatvalue});
  }

  handleTermsChange(event) {
    this.setState({ terms: event.target.checked });
  }

  // handleCauseChange(event) {
  //   const cause = data.charities.find(element => element.accountNumber == event.target.value);
  //   if (cause !== undefined) {
  //     this.setState({ cause: cause });
  //   }
  // }

  async handleRouting() {
    if (this.state.amount >= 1 && this.state.terms) {
      history.push({
        pathname: '/donate',
        state: { amount: this.state.amount, currency: this.state.currency }
      });
    } else {
      alert(`Please make sure you have provided a donation amount of at least $1 and agreed to the terms and conditions.`);
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  getCharities() {
    let charities = [];
    for (let i = 0; i <= data.charities.length - 1; i++) {
      charities.push(<option value={data.charities[i].accountNumber}>{data.charities[i].name}</option>);
    }
    return charities;
  }

  render() {
    return (
       <div className="donation-container">
          <div className="donation-container-help">
            <p>HELP BY DONATING</p>

            <CurrencyInput className="currencyInput" prefix="$" value={this.state.amount} onChangeEvent={this.handleAmountChange}/>

            <div className="terms">
              <h5><input type="checkbox" onChange={this.handleTermsChange} value={this.state.terms}/>I accept the <Link onClick={this.handleOpenModal}>Terms & Conditions</Link></h5>
              <Modal isOpen={this.state.showModal}
              onRequestClose={this.handleCloseModal}
              className="Modal"
              overlayClassName="Overlay"
              contentLabel="Terms and Conditions">
              <Terms closeModal={this.handleCloseModal}/>
              </Modal>
            </div>

            <div className="button">
              <button onClick={this.handleRouting}>Donate Now</button>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(DonationOptions);